import React from "react";
import { Link } from "../components/links";
import { Hero } from "../components/hero";
import CTA from "../components/cta";
import "../styles/pages/compensation-survey.scss";
import TypeformEmbed from "../components/forms/typeform-embed";

const CompensationSurvey = () => {
  const heroData = {
    title: "Compensation Survey",
  };
  return (
    <section className="compensation-survey">
      <Hero data={heroData} />
      <div className="compensation-survey__inner">
        {/* <TypeformEmbed typeformId={"dBk48Mn4"} /> */}
        <div className="compensation-survey__cta">
          <p>
            Our in-house legal and compliance compensation survey, collected
            annually for over 20 years, is available only to clients and
            participants. If you contributed in 2023, please click below to view
            results.
          </p>
          <p>
            If you are interested in contributing to our next iteration, data
            collection will resume in 2025.
          </p>
          <div className="compensation-survey__cta__index">
            <CTA to="/survey-results/2024">2024 Survey Results</CTA>
            {/* <CTA to="/survey-results">Past Survey Results</CTA> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompensationSurvey;
